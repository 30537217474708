import { useState } from 'react';
import {  NumericFormat } from 'react-number-format';
import styles from './MortSummaryPanel.module.css';




const MortSummaryPanel = (props) => {

    const m1 = { ...props.m1 }
    const m2 = { ...props.m2 }




    const [open, setOpen] = useState(true);

    const toggleOpen = () => {
        setOpen(!open);
    };


    return (
        <div className={`${styles.MortSummaryPanel} ${open ? styles.open : null} `}>
            <div className="title" onClick={toggleOpen} >

                <h1>Mortgage Summary</h1>
            </div>

            <div className="column_1">
                <h2>Mortgage 1</h2>
                <div>
                    <label>Monthly Payment </label>
                    <h3>
                    <sup>£</sup>
                        <NumericFormat
                            value={m1.payment}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>
                <div>
                    <label>Annually </label>
                    <h3>
                    <sup>£</sup>
                        <NumericFormat
                            value={m1.annually}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>
                <div>
                    <label>Total Repaid </label>
                    <h3>
                    <sup>£</sup>
                        <NumericFormat
                            value={m1.total_repayments}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>
                <div>
                    <label>Total Interest </label>
                    <h3>
                    <sup>£</sup>
                        <NumericFormat
                            value={m1.total_interest}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>


            </div>
            <div className="column_2">
                <h2>Mortgage 2</h2>
                <div>
                    <label>Monthly Payment </label>
                    <h3>
                    <sup>£</sup>
                        <NumericFormat
                            value={m2.payment}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>
                <div>
                    <label>Annually </label>
                    <h3>
                    <sup>£</sup>
                        <NumericFormat
                            value={m2.annually}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>
                <div>
                    <label>Total Repaid </label>
                    <h3>
                    <sup>£</sup>
                        <NumericFormat
                            value={m2.total_repayments}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>
                <div>
                    <label>Total Interest </label>
                    <h3>
                        <sup>£</sup>
                        <NumericFormat
                            value={m2.total_interest}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator={true}
                            displayType="text"
                            thousandsGroupStyle="thousand" />
                    </h3>
                </div>


            </div>
        </div>


    )

}

export default MortSummaryPanel