import React, { useState } from 'react'
// import { Line } from 'react-chartjs-2';
import styles from './MortGraphPanel.module.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MortGraphPanel = (props) => {




    const m1_months = [...Array(props.m1.term * 12).keys()]
    const m2_months = [...Array(props.m2.term * 12).keys()]

    let M = { b1: [], b2: [], p1: [], p2: [], i1: [], i2: [] }


    for (const index of m1_months) {
        M.b1[index] = props.m1.a_balance[index]
        M.p1[index] = props.m1.c_principal[index]
        M.i1[index] = props.m1.c_interest[index]
    }

    for (const index of m2_months) {
        M.b2[index] = props.m2.a_balance[index]
        M.p2[index] = props.m2.c_principal[index]
        M.i2[index] = props.m2.c_interest[index]

    }



    const data = {
        labels: m1_months > m2_months ? m1_months : m2_months,
        datasets: [
          {
            label: 'M1: Balance ',
                data: M.b1,
                pointRadius: 0,
                borderWidth: 3,
                borderColor: 'lightcoral',
          },
          {
              label: 'M1: Principal',
              data: M.p1,
              pointRadius: 0,
              borderWidth: 3,
              borderColor: 'orange',

          },
          {
              label: 'M1: Interest',
              data: M.i1,
              pointRadius: 0,
              borderWidth: 3,
              borderColor: 'gold',

          },
          {
              label: 'M2: Balance ',
              data: M.b2,
              pointRadius: 0,
              borderWidth: 3,
              borderColor: 'lightseagreen',
          },
          {
              label: 'M2: Principal',
              data: M.p2,
              pointRadius: 0,
              borderWidth: 3,
              borderColor: 'mediumaquamarine',

          },
          {
              label: 'M2: Interest',
              data: M.i2,
              pointRadius: 0,
              borderWidth: 3,
              borderColor: 'paleturquoise',

          }
        ]
      }
    
    const options = {
        
        scales: {
            x: {
                color: 'red',

            },
            y: [{
                display: true,
                ticks: {

                    maxTicksLimit: 8,
                    beginAtZero: true,
                    stepSize: 100000,
                },
                scaleLabel: {
                    // display: true,
                    // labelString: 'Amount (GBP)'

                },
            }],
            
        },
      


    }

    const testoptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
              ticks: {
                maxTicksLimit: 10
              }
            },
            y: {
              ticks: {
                maxTicksLimit: 8
              }
            }
          },



        animation: {
            duration: 0, // general animation time
            hover: {
                animationDuration: 0 // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0 // animation duration after a resize
        },
     
        layout: {
            padding: {
                bottom: 40
            }
        },

        plugins: {
            legend: {
                display: true,
                fullWidth: false,

                position: 'bottom',
                labels: {
                    fontSize: 12,
                    boxHeight:1,
                    boxWidth: 35,
                    padding: 20,
                }
            },
           
        }

    }



    return (

        <>
        <div className={styles.MortGraphPanel}>
            <div className="title"  >

                <h1>Comparison Graph</h1>
            </div>
           <Line  data={data} options={testoptions} />

        </div>
        </>
    )

}
export default MortGraphPanel