import React from 'react'
import MortCalc from './Container/MortCalc/MortCalc'
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import  'globals.grids.css'






function App() {




  return (
    <div style={{ background: 'white', minHeight: "100vh" }}>
      {/* <BrowserRouter>
      <Routes>
        <Route path="/" element={<MortCalc />} />
      </Routes>
      </BrowserRouter> */}

      <MortCalc />
        {/* <h1>Helo World/</h1> */}
       
    </div>
  );
}

export default App;
