import React, { Component } from "react"
import CurrencyInput from 'react-currency-input-field';
import styles from "./MortInputPanel.module.css"


class MortInputPanel extends Component {


    render() {



        return (


            <div className={styles.MortInputPanel}>
                <div className="title" >
                    <h1>Mortgage Comparison Calculator</h1>
                </div>
                <div className="column_1">
                    <h2 onClick={(e) => this.props.inputChangeHandler(undefined, undefined, "m1", "m2")}>Mortgage 1</h2>

                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m2.principal, "principal", "m1")}>Loan Amount</label>
                    <CurrencyInput
                        value={this.props.m1.principal}
                        name='principal'
                        prefix="£"
                        onValueChange={(value, name) => this.props.inputChangeHandler(value, name, "m1")}
                    />
                    <input type="range" name='principal' min="50000" max="2000000" step="500" value={this.props.m1.principal} onChange={(e) => this.props.inputChangeHandler(e.target.value, e.target.name, "m1")} />

                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m2.rate, "rate", "m1")}>Interest Rate</label>
                    <CurrencyInput
                        value={this.props.m1.rate}
                        name='rate'
                        suffix="%"
                        decimalsLimit='2'
                        fixedDecimalLength='2'
                        onValueChange={(value, name) => this.props.inputChangeHandler(value, name, "m1")}
                    />
                    <input type="range" name='rate' min="1" max="15" step="0.25" value={this.props.m1.rate} onChange={(e) => this.props.inputChangeHandler(Number(e.target.value).toFixed(2), e.target.name, "m1")} />

                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m2.term, "term", "m1")}> Term (Years)</label>
                    <CurrencyInput name='term' value={this.props.m1.term} onValueChange={(value, name) => this.props.inputChangeHandler(value, name, "m1")} />
                    <input type="range" name='term' min="2" max="35" step="1" value={this.props.m1.term} onChange={(e) => this.props.inputChangeHandler(e.target.value, e.target.name, "m1")} />

                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m2.intonly, "intonly", "m1")}>Interest Only</label>
                    <input className={styles.checkmark} type="checkbox" id="m1" name='intonly' checked={this.props.m1.intonly} onChange={(e) => this.props.inputChangeHandler(e.target.checked, e.target.name, "m1")} />

                </div>
                <div className="column_2">
                    <h2 onClick={(e) => this.props.inputChangeHandler(0, 0, "m2", "m1")}>Mortgage 2</h2>
                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m1.principal, "principal", "m2")}>Loan Amount</label>

                    <CurrencyInput name='principal' value={this.props.m2.principal}  prefix="£" onValueChange={(value, name) => this.props.inputChangeHandler(value, name, "m2")} />
                    <input type="range" name='principal' min="50000" max="2000000" step="500" value={this.props.m2.principal} onChange={(e) => this.props.inputChangeHandler(e.target.value, e.target.name, "m2")} />

                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m1.rate, "rate", "m2")}>Interest Rate</label>
                    <CurrencyInput name='rate' value={this.props.m2.rate} suffix="%" onValueChange={(value, name) => this.props.inputChangeHandler(value, name, "m2")} />
                    <input type="range" name='rate' min="0.1" max="15" step="0.1" value={this.props.m2.rate} onChange={(e) => this.props.inputChangeHandler(e.target.value, e.target.name, "m2")} />

                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m1.term, "term", "m2")}> Term (Years)</label>
                    <CurrencyInput name='term' value={this.props.m2.term} onValueChange={(value, name) => this.props.inputChangeHandler(value, name, "m2")} />
                    <input type="range" name='term' min="2" max="35" step="1" value={this.props.m2.term} onChange={(e) => this.props.inputChangeHandler(e.target.value, e.target.name, "m2")} />

                    <label onClick={(e) => this.props.inputChangeHandler(this.props.m1.intonly, "intonly", "m2")}>Interest Only</label>
                    <div>
                        <input
                            className={styles.toggleSwitch}
                            type="checkbox"
                            id="m1"
                            name='intonly'
                            checked={this.props.m2.intonly}
                            onChange={(e) => this.props.inputChangeHandler(e.target.checked, e.target.name, "m2")}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default MortInputPanel